/******************************************************************
Site Name: 
Author: 

Stylesheet: Super Large Monitor Stylesheet

You can add some advanced styles here if you like. This kicks in
on larger screens.

******************************************************************/
h1, .h1, h2, .h2, h3, .h3, h5, .h5, h6, .h6,{margin:0.5em 0;}
h4, .h4 {margin:1em 0;}
.responsive .h3 {margin-bottom:0; }
ul.responsive3 .slideshow_caption p.h1, .heading h1 { font-size: 4em; line-height: 1em; }
ul.responsive3 .slideshow_caption p.h2 { font-size: 2em; }
h2, .h2 { font-size: 2.4em; }
.topMenu .nav > li > a {font-size: 110%;}
 .bigger, h3, .h3, h4, .h4, #address .h2, #accountMenu .h2, #content ol:not(.slick-slider) li:before {font-size: 1.4em;}

/*********************
LAYOUT & GRID STYLES
*********************/
.row, body.Home #content #leftcol > div > div/*, body.fullWidth #leftcol > div > div*/ {
	padding: 2.5em 0;
}
	
/*********************
HEADER STYLES
*********************/




/**************************
HERO - Home
***************************/

body:not(.Home) #hero {
   height:300px;
    overflow:hidden;
       p img {
            min-width:100%;
            max-width:initial;
            height:auto;
        }
}


/*********************
NAVIGATION STYLES
*********************/

.topMenu {		
	.nav {		
		> li {
			padding: 0 1em;
		}
	}
}



/*********************
CONTENT STYLES
*********************/

#content #leftcol > div > .first {padding:0 2em;}


/*********************
BLOCK LAYOUT
**********************/	


.columnlayout {
	
	> div, li {
		
	}
    /*Top row needs border adding seperatley to prevent double borders*/
    > div:nth-child(4) , li:nth-child(4) {
		/*border-top: 1px solid darken($white, 25%);*/
	}
}


 #bottomBlocks {
     .block {margin-bottom:0em;}

 }

 #homeServices {       
    > div {padding: 3em 2em;}
}

 /* LEFT BLOCKS - Mission statement*/
    #homeMission{
    }

 
 /*Full width slider */
ul.responsive3 {
	
	.slick-list {
		.slick-track {
			.slick-slide {
				/*height:auto;*/
				img {
					/*max-width: 100%;
					width: 100%;
					height: auto;
					max-height: none;*/
				}
			
			}
		}
	}
}
